.landing {
	position:relative;

	.top-bar {
		padding: 1rem 8.33%;

		.anchor-wrap {
			width: 200px;
			margin-top: -2.5rem;
			a {	
				text-align: center;
				span { 
					display: block;
					width: 36px;
					height:36px;
					border-radius: 50%;
 					color: white;
					font-weight: bold;
					transition: opacity .3s ease;
					line-height: 36px;
					font-family: $hand;
					font-weight: bold;
					font-size: 1.5rem;
					background-size: contain;
					background-repeat: no-repeat;
					opacity: 0;
				}
			}
		}
		.grid-x {
			.headline {
				h1 {
					font-size: 2.25rem !important;
				}
				div {
					font-size: 1.25rem !important;
					color: $coffee;
					display: inline-block;
				}
			}
		}
		&.is-stuck {
			border-bottom: 2px solid $coffee;
			background: white;

			.grid-x {
				.headline {
					h1 {
						font-size: 2.25rem !important;
					}
					div {
						font-size: 1.25rem !important;
						color: $coffee;
						display: block;
					}
				}
			}
			.anchor-wrap a span { 
				opacity: 1;
			}
		}
	}
	.single-page {
		width: 100%;
		height: calc(100vh - 82px);
		z-index: 1;
		text-align: center;
		position: fixed;
		top: 77px;
		display: flex;
		flex-direction: column;

		.center-wrap {
			max-width: 1500px;
			margin: auto;
			flex-grow: 1;
			h4 { 
				font-family: $hand;
				font-weight: bold;
				margin: 4vh 0 7vh;
				font-size: 2rem;
				color: $coffee;
			}

			.link-wrap {
				position: relative;
				margin: 10vh 0;
				&:before, &:after {
					content:"";
					position: absolute;
					display: block;
					height: 100%;
					background-repeat: no-repeat;
					background-size: contain;
				    z-index: -1;
				    opacity: $sketch;
				}
				&:before {
					background-image: url(../img/s_long.png);
				    width: 65%;
    				top: -17%;
				}
				&:after {
					background-image: url(../img/ar_long.png);
					transform: rotate(180deg) scaleX(-1);
					width: 40%;
				    left: 17%;
				    top: 10%;
				}
				a {
					position: relative;
					margin: 2rem 3rem;
					background-size: contain;
					background-repeat: no-repeat;

					&:first-of-type {
						background-image: url('../img/b1e.png');

						&:before {
							background-image: url('../img/b1f.png');
						}
					}
					&:nth-of-type(2) {
						background-image: url('../img/b4e.png');

						&:before {
							background-image: url('../img/b4f.png');
						}
					}
					&:nth-of-type(3) {
						background-image: url('../img/b3e.png');

						&:before {
							background-image: url('../img/b3f.png');
						}
					}

					&:nth-of-type(4) {
						background-image: url('../img/b4e.png');

						&:before {
							background-image: url('../img/b4f.png');
						}
					}

					&:before {
						content: "";
						display: block;
						padding-top: 100%;
						background-size: 25%;
						background-repeat: no-repeat;
						background-position: center;
						opacity: 0;
						transition: all .1s cubic-bezier(.07,.89,.44,.99);
						z-index: 0;
						cursor: pointer;
					}
					&:nth-of-type(2):after, &:nth-of-type(3):after {
						    content: "";
						    display: block;
						    width: 4.5rem;
						    height: 100%;
						    background-image: url(../img/ar_forward.png);
						    background-repeat: no-repeat;
						    background-position: center;
						    top: 0;
						    right: -5.25rem;
						    position: absolute;
						    background-size: contain;
						    opacity: $sketch;
					}
					&:nth-of-type(2):after {
						background-size: 80%;
					}

					&:nth-of-type(3):after {
						background-image: url('../img/ar_vert.png');
						height: 80%;
						top: 10%;
					}
					// &:last-of-type:after {
					// 	content: "";
					//     width: 100%;
					//     position: absolute;
					//     display: block;
					//     background-image: url(../img/ar_arch_left.png);
					//     height: 100%;
					//     background-size: contain;
					//     background-repeat: no-repeat;
					//     left: -81%;
					//     top: 27%;
					//     opacity: .4;
					//     background-position: bottom;
					// }

					&:hover {
						cursor: pointer;
						&:before {
							background-size: 97%;
							opacity: .15;
						}
						span {
							text-shadow: 0 0 7px transparentize($coffee, .6);
						}
					}
					&.hovering:before {
						transition: all .5s cubic-bezier(.07,.89,.44,.99);
					}

					span {
						font-family: 'Amatic SC';
						font-size: 2rem;
						font-weight:bold;
						color: $coffee;
						position:absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						text-align: center;
						transition: color .5s ease-in;
						cursor: pointer;
						text-shadow: 0 0 0 transparentize($coffee, 0);
						transition: text-shadow cubic-bezier(.07,.89,.44,.99);
					}
				}
				.arrows {
				    position: absolute;
				    height: 68%;
				    width: 11%;
				    left: 19%;
				    margin-top: 3.25%;
				    opacity: $sketch;
					&:before, &:after {
						content: "";
						position: absolute;
						display: block;
						width: 100%;
						background-image: url(../img/ar_arch_right.png);
						background-repeat: no-repeat;
						background-size: contain;
						height: 100%;
						top: 0;
					}
					&:after {
						transform: rotate(180deg);
					}
				}
				.arrows2 {
					position: absolute;
					width: 100%;
					height: 100%;
				    transform: rotate(180deg);
				    z-index: -1;

					&:before, &:after {
						content:"";
						position: absolute;
						display: block;
						height: 100%;
						background-repeat: no-repeat;
						background-size: contain;
					    z-index: -1;
					    opacity: $sketch;
					}
					&:before {
					    background-image: url(../img/ar_arch_right.png);
					    width: 12%;
					    top: 21px;
					    left: 19%;
					}
					&:after {
						background-image: url(../img/ar_long.png);
						transform: rotate(180deg) scaleX(-1);
						width: 34%;
					    left: 22%;
					    top: 2%;
					}
				}
				.footnote {
					position: absolute;
					right: 0;
					bottom: 0;
					font-family: $hand;
					color: $coffee;
					text-align: left;
					left: 65%;
					bottom: -25%;
				}
			}
			.statement {
				margin-top: 6vh;
				justify-content: center;
				p { 
					text-align: left;
					font-weight: 300;
				}
			}
		}
		.fold-break {
			h4 {
				font-size: 1.3125rem;
			}
		}
	}

	

	.case-wrapper {
		section {
			&.case { margin-bottom: 50vh;}

			&:not(.filler) {
				color: red;
				font-weight: bold;
				position: relative;
				z-index: 4;
				

				&#case1 div {
					background: black;
				}
				&#case2 div {
					background: gray;
				}
				&#case3 div {
					background: blue;
				}
				div {
					height: calc(100vh - 5.467rem);
					box-shadow: -1px 0px 5px 2px transparentize(black, .7);
				}
			}
			&#filler{ height: 100vh; }
		}
	}
}
.landing.temp {
	height: 100vh;
	padding-top: 10vh;
	color: $darkcoffee;
	font-family: raleway;
	font-weight: 300;

	.portrait {
		width: 80px;
		height: 80px;
		margin: auto;
		background-image: url('../img/me_icon.png');
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
	}
	.greeting {
		>div { 
			margin: auto;
			text-align: center;
		}
		h1
			{
			color:$coffee;
			font-weight: 300;
			font-family: $hand;
			margin-top: 18px;
			margin-bottom: -5px;
		}
		p:first-of-type {
			margin-bottom: 27px;
		}
	}
	.links {
		>div {
			margin: auto;

			a {
				color: $coffee;
				width: 100%;
				text-align: center;
				disply: block;
				div {
					height: 39px;
					margin: 10px auto;
					line-height: 45px;
					background-size: 25px;
					background-position: 10px center;
					background-repeat: no-repeat;
					position: relative;

					&.linked {
						background-image: url('../img/linked_logo.png');
					}
					&.vimeo {
						background-image: url('../img/vimeo_logo.png');
					}
					&.email {
						background-image: url('../img/mail_icon.png');
					}
					&:after {
						content: "";
						position: absolute;
						width: 100%;
						height: 100%;
						left: 0;
						top: 0;
						background-image: url('../img/anchor_border.png');
						background-size: 100% 100%;
						transition: opacity .3s ease;
						opacity: 0;
					}
				}
				&:hover {
					div:after {
						opacity: .6;
					}
				}
			}
		}
	}
	.temp-footer {
		position: fixed;
		bottom: 0;
		width: 100%;
		text-align: center;
		font-family: $hand;
		font-size: 20px;
		color: $darkgray;
		padding-bottom: 5px;
	}
}









