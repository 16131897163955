.focus-mask {
	width: 100vw;
	height: 100vh;
	display: none; 
	background-color: transparentize($darkcoffee, .5);
	position: fixed;
	bottom: -81px;
	left: 0;
	z-index: 20;
	&.focused {
		display: block;
	}
}
.section-wrapper {
	padding: 0 8.33%;
	transition: margin-left .15s ease-in;
	display: flex;
	flex-direction: column;
	height: calc(100% - 62px);
	overflow-y: auto;

	.page-head {
		margin-top: 1rem;
		margin-bottom: 2rem;
	}
	.page-body {
		.sidebar {
			transition: opacity .7s ease;
			.medalion {
				margin-left: -1rem;
				margin-top: 1.33rem;
				z-index: 0;
				position:relative;
				width: 50%;
				padding-top: 50%;
				margin-bottom: 1.33rem;

				&:before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-image: url(../img/b1e.png);
					background-size: contain;
					background-repeat: no-repeat;
				}

				span {
					position: absolute;
					line-height: 50%;
					top: 50%;
					left: 0;
					width: 100%;
					text-align: center;
					transform: translatey(-50%);
					color: $darkcoffee;
					font-family: $hand;
					font-weight: bold;
					font-size: 1.33rem;
				}
			}

			.topic-list, .toolbox {

				li { 
					font-size: .8667rem;
					color: #808080;
					line-height: 24px;
				}

				h6 { 
					font-size: 1rem;
					font-family: 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
					font-weight: 500;
					color: $darkcoffee;
				}
			}
			.toolbox {
				padding-top: .67rem;
				position: relative;

				&:before {
					content: "";
					width: 50%;
					border-top: 1px solid $coffee;
					position: absolute;
					top: 0;
					left: 0;
				}
			}

		}
		.content-wrapper {
			max-width: 1200px;
			padding-bottom: 10vh;
			.page-head { 
				padding-left: 2rem;
				color: #404040;
			}

			h6 {
				font-size: 1rem;
				font-weight: light;
			}
			.project {
				padding-top: .666rem;
				padding-bottom: .667rem;
				margin-bottom: 3rem;
				transition: margin-left .2s ease-in, opacity .7s ease, border-color .2s ease-in,;
				flex-grow: 1;
				overflow-y: auto;
				position: relative;
				z-index: 0;
				background: white;
				border-color: transparent;

				.re-positioner {
					position: absolute;
					top: -150px;
				}


				.project-image {
					padding: 0 2rem;
					transition: all .2s ease-in;
					transition-delay: .15s;

					div {
						overflow: hidden;
						outline-color: #ccc;
						&.border {
							border: 1px solid #ccc; 
						}
						img{
							transition: transform .35s ease;
						}
					}
					&:hover {
						cursor: pointer;
						div { 
							outline: 1px solid #ccc;
							outline-offset: -1px;
							transition: outline .2s ease-in;
							img {
								transform: scale(1.2);
								transition: transform 1.5s ease;
							}
						}
					}
					&.hover-border {
						div {
							transition-delay: 1s;
							transition: border .2s ease;
						}
						&:hover div {
							border:1px solid #ccc;
						}
					}
				}
				.project-description {
					.description-content {
						>div:not(.name) { font-size: .8667rem;}
						>.grid-x {
							margin-bottom: .667rem;
							.content-label {
								color: #ccc;
								padding-right: 1rem;
							}
							&.name {
								color: #404040;
								font-weight: 500;
								span {
									text-transform: uppercase;
									font-size: .75rem;
									letter-spacing: .75px;
									font-weight: 300;
									white-space: pre;
									padding-left: .333rem;
								}
							}
							&.type {
								ul li {
									display: inline-block;
									color: #808080;

									&:not(:last-child):after {
										content: ",";
									}
								}
							}
							&.outcomes {
								color: $sky;
							}
						}
						.small-2 { transition: all .15s ease-in;}
					}
					.open-trigger {
						padding-right: 1rem;
						a {
							display: flex;
							width: 50px;
							height: 50px;
							background: white;
							border-radius: 50%;
							border: 1px solid transparentize($coffee, .5);
							margin-left: auto;
							transition: margin-left .15s ease-in;
							margin-top: 2rem;
							align-items: center;
							justify-content: center;
							color: $coffee;
							line-height: .5;
							font-family: $hand;
							font-weight: bold;
							text-align: center;
							transition: all .3s ease-in;

							span { 
								transition: width .3s ease-in;
							}
							&:before, &:after {
								content: "";
								position: absolute;
								opacity: 0;
								transition: all .3s ease-in;
							}
							&:before {
								width: 16px;
								height: 16px;
								border: 2px solid white;
								border-width: 2px 0 0 2px;
								transform: rotate(-45deg);
								border-radius: 1px
							}
							&:after {
								width: 1.33rem;
								border-top: 2px solid white;
							}


							&:hover {
								background: transparentize($coffee, .5);
								span {
									overflow: hidden;
									width: 0;
								}
								&:before, &:after {
									opacity: 1;
								}
							}
						}
					}
				}
			}
		}
	}
}
.footer {
	width: 100%;
	border-top: 1px dashed transparentize($coffee, .8);
	font-family: $hand;
	color: transparentize($coffee, .65);
	font-weight: bold;
	font-size: 1.5rem;
	text-align: center;
	line-height: 30px;

	a {
		color: $coffee;
	}
	&.tall {
		line-height: 30vh;
	}
	&.nav-footer {
		padding: 1rem 0 ;
		.prev {
			border-right: 1px solid lighten($darkcoffee, 55%);
		}
	}
	&.linkGroup1 {
		.prev {
			a { display: none;}
		}
		.next {
			a:not(:first-of-type) {
				display: none;
			}
		}
	}
	&.linkGroup2 {
		.prev {
			a:last-of-type { display: none;}
		}
		.next {
			a:first-of-type {
				display: none;
			}
		}
	}
	&.linkGroup3 {
		.prev {
			a:not(:last-of-type) { display: none;}
		}
		.next {
			a{
				display: none;
			}
		}
	}
}
.gallery {
	font-size: .8667rem;
	color: $darkcoffee;
	position: fixed;
	left: 100%;
	top: 82px;
	width: 70%;
	height: calc(100% - 82px);
	z-index: 5;
	background: white;
	transition: left .3s ease-in;
	z-index: 30;
	border-left: 2px solid $darkcoffee;

	#close-gallery {
		position: absolute;
		top: .333rem;
		right: 1.5rem;
		color: $darkcoffee;
		font-family: $hand;
		font-weight: bold;
		font-size: 1.133rem;
		opacity: .8;
		transition: opacity .3s ease;

		&:hover { 
			cursor: pointer;
			opacity: 1;
		}
	}
	.gallery-scroller {
		height: 100%; 
		overflow-y: auto;
		padding: 2rem 4rem 1.33rem;

		.section-description {
			margin-bottom: 2.66rem;
			color: $darkgray;
			span {
				font-weight: 600;
				font-size: 1rem;
				color: $darkcoffee;
			}
		}
		section {
			margin-bottom: 4rem;
			p { 
				text-align: justify;
				color: $darkgray;
				line-height:1.667;
			}
			h4 {
				font-size: .8667rem;
				font-weight: 600;
				margin-bottom: .333rem;
				color: $coffee;
			}
			&.image {
				p { 
					padding-right: 3rem;
					text-align: justify;
				}
				.image-column {
					position: relative;

					.image-wrapper {
						position: relative;
						
						a {
							width: 100%;
							height: 100%;
							background-position: center top;
							background-size: contain;
							background-repeat: no-repeat;
							display: block;
							position: absolute;
							top: 0;
							left: 0;

							&.border {
								border: 1px solid $coffee;
							}
						}
						&.doc {
							background-color: $darkgray;
							text-align: center;
							position: relative;
							&:after {
								content: '';
								position: absolute;
								right:10px;
								bottom: 10px;
								display: block;
								width:60px;
								height:70px;
								background-image: url(../img/doc-icon-wh.png);
								background-repeat: no-repeat;
								background-size: contain;
								opacity: .4;
								transition: opacity .3s ease;
							}
							&:hover:after {
								opacity: .8;
							}
							a {
								color: white;
								font-family: $hand;
								font-weight: 700;
								font-size: 24px;
								padding-top: 40px;
							}
						}
						&.background {
							background-color: lighten($darkcoffee, 70%);
							border: 1px solid lighten($darkcoffee, 40%);
						}
						&.border {
							border: 1px solid lighten($darkcoffee, 40%);
						}
						.subHead {
							font-size: 18px;
							display: block;
							font-weight: 400;
						}
						&.land-1 {
							padding-top: 60.73%;
						}
						&.land-spec {
							padding-top: 49.7%;
						}
						&.port-1 {
							padding-top: 129.4%;
						}
						&.port-half {
							padding: 64.7% 2rem 0;
							a {
								padding-top: 50px;
								background-size: cover;
							}
						}
						&.port-third {
							padding: 43.13% 2rem 0;
							&:first-of-type {
								margin-bottom: 1rem;
							}
							a {
								background-size: cover;
							}
						}
						&.notebook {
							padding-top: 69.75%;
							border: none;
						}
						&.notebook-half {
							padding-top: 34.875%;
							border: none;
							&:first-of-type {
								margin-bottom: 1rem;
							}
							a {
								padding-top: 50px;
								background-size: cover;
							}
						}
						&.custom-wrap-1 {
							padding-top: 84.2%;
						}
						&.no-click a{
							pointer-events: none;
							cursor: default;
						}
					}
				}
			}
			&.sidebar-images {
				.medium-3 {
					padding: 1rem 2rem 0;
					p {
						text-align: center;
					}
				}
				&.sentric-ux-testing .medium-3 {
					p {
						font-size: 27px;
						font-weight: bold;
						color: red;
						text-align: center;
					}
				}
			}
			&.summary, &.links {
				margin-bottom: 2rem;
				h4, p {
					display: inline;
					color: $sky;
				}
				p {
					margin-left: .33rem;
					padding-left: .5rem;
					border-left: 1px solid $darkgray;
					color: $darkgray;
				}
				a {
					color: $sky;
					font-weight: 600;
				}
			}
			&.last {
				margin-bottom: 2rem;
				padding-bottom: 1rem;
				border-bottom: 1px solid #ebebeb;
			}
			//research image links
			.sen-res-img-1 {
				background-image: url(../img/sen-res-img-1.jpg);
			}
			.sen-res-img-2 {
				background-image: url(../img/sen-res-img-2.jpg);
			}
			.adsm-res-img-1 {
				background-image: url(../img/adsm-res-img-1.png);
			}
			//ux image links
			.wheel-ux-img-1 {
				background-image: url(../img/wheel-ux-img-arch.png);
			}
			.wheel-ux-img-2 {
				background-image: url(../img/wheel-ux-img-work.png);
			}
			.wheel-ux-img-3 {
				background-image: url(../img/wheel-ux-img-wire.png);
			}
			.wheel-ux-img-4 {
				background-image: url(../img/wheel-ux-img-spec.jpg);
			}
			.wheel-ux-img-5 {
				background-image: url(../img/wheel-ux-img-bus.png);
			}
			.sen-ux-img-1 {
				background-image: url(../img/sen-ux-img-workSketch.jpg);
			}
			.sen-ux-img-2 {
				background-image: url(../img/sen-ux-img-archSketch.jpg);
			}
			.sen-ux-img-3 {
				background-image: url(../img/sen-ux-img-Sketch.jpg);
			}
			.sen-ux-img-4 {
				background-image: url(../img/sen-ux-img-landingSketch.jpg);
			}
			.sen-ux-img-5 {
				background-image: url(../img/sen-ux-wire-composite.png);
			}
			.sen-ux-img-6 {
				background-image: url(../img/sentric-mobile-wires-1.jpg);
			}
		}
	}
}
.section-wrapper.focus-mode {
	.project {
		&.focused-project {
			margin-left: -24%;
			overflow: hidden;
			box-shadow: 0 0 5px transparentize(black, .85);
			border-radius: 1.33rem;
			border: 1px solid transparentize($coffee, .25);
			z-index: 25;
			
			.project-image {
				margin-left: -50%;
				height: 0;
				overflow: hidden;
				transition-delay:0s;
			}
			.description-content {
				width: 60%;
				.small-2 {
					width: 0; 
					padding-right: 2rem !important;
				}
			}
		}
	}
	.page-head, .content-wrapper h6, .sidebar{
		opacity: .2;
		z-index: -1;
	}
	.gallery {
		left: 30%;
	}
}
.reveal {
	max-height:90vh;
	border: none;
	padding: 0;
	.image-wrapper {
		width: 100%;
		background-size: cover;
		background-repeat: no-repeat;
	}
	&#sentric-research-1 {
		.image-wrapper {
			padding-top: 60.7%;
			background-image: url(../img/sen-res-img-1.jpg);
		}
	}
	&#sentric-research-2 {
		.image-wrapper {
			padding-top: 60.7%;
			background-image: url(../img/sen-res-img-2.jpg);
		}
	}
	&.large {
		@include flexbox;
		@include flex-direction(column);
		overflow: hidden;
		.modal-body {
			flex-grow:1;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
		}
		&.full-h {
			height: 90vh;
		}
	}
}