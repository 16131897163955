// html{ 
// 	height: 100vh;
// 	overflow: hidden;
// }
@keyframes slide-down{
	from {margin-top: 2rem;}
	to {margin-top: 0;}
}
h1, h2, h3, h4, h5, h6 {
	font-family: 'Libre Franklin','Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
}
body {
	position: relative;
	&.no-scroll {
		overflow: hidden;
	}
}
.top-bar {
	padding: 1rem 8.33%;
	flex-wrap: wrap;
	background-color: transparent;

	.grid-x {
		width: 100%;
		justify-content: center;

		.headline {
			h1, div {
				display: inline;
				transition: all .3s ease-in;
			}
			h1 {
				color: $darkcoffee;
				font-size: 1.5rem;
				font-weight: 500;
				margin-right: .5rem;
			}
			div {
				font-size: .875rem;
				font-weight: 300;
				color: $coffee;
				display: block;
			}
		}

		.sub-nav {
			align-self:flex-end;
			padding-bottom: .33rem;
			ul li ul{
				float: right;
				width: 25px;
				height: 25px;
				margin-left: 1.33rem;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
				background-color: transparent;
				opacity: .75;
				cursor: pointer;
				transition: opacity .3s ease;

				&.contact {
					background-image: url('../img/mail_icon.png');
				}
				&.links {
					background-image: url('../img/link_icon.png');
				}
				&.me {
					background-image: url('../img/me_icon.png');
					height: 40px;
					margin-top:-10px;
				}

				&:hover {
					opacity: 1;
				}
			}
		}
		#sub-nav-hints {
			position: absolute;
			right: calc(8.33% - 1rem);
			bottom: 0;
			height: 1.5rem;
			overflow:hidden;
			color: $coffee;
			font-family: $hand;
			font-weight: bold;
			font-size: 1rem;
			overflow: hidden;

			div {
				padding: 0 1rem;
				margin-top: -2rem;
				display: none;
				animation: slide-down .15s ease-in;
				animation-fill-mode: forwards;


				&.show {
					display: block;
				}
			}
		}
		.anchor-wrap {
			width: 200px;
			margin-top: -2.5rem;
			a {	
				text-align: center;
				span { 
					display: block;
					width: 36px;
					height:36px;
					border-radius: 50%;
 					color: white;
					font-weight: bold;
					transition: opacity .3s ease;
					line-height: 36px;
					font-family: $hand;
					font-weight: bold;
					font-size: 1.5rem;
					background-size: contain;
					background-repeat: no-repeat;
					opacity: 0;
				}
			}
		}
	}
	
	&.is-stuck, &.test {
		// background: $darkcoffee;
		border-bottom: 2px solid $coffee;
		background: white;
		.grid-x {
			.headline {
				h1 {
					font-size: 1.5rem;
				}
				div {
					font-size: .875rem;
					color: $coffee;
					display: block;
				}
			}
		}
		.anchor-wrap a  { 
			&:first-of-type span { background-image: url('../img/s4f.png') !important;}
			&:nth-of-type(2) span { background-image: url('../img/s2f.png') !important;}
			&:nth-of-type(3) span { background-image: url('../img/s3f.png') !important;}
			&:nth-of-type(4) span { background-image: url('../img/s4f.png') !important;}
			span { opacity: 1;}
		}
	}
}
.reveal {
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0,0,0,0.4);
	.heading {
		padding: 0 2rem;
		border-bottom: 1px solid $darkcoffee;
		margin-bottom: 1px;
		h1 {
			font-family: 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
			font-size: 1rem;
			line-height: 2.5rem;
			font-weight: 500;
			color: $darkcoffee;
			padding-top: 7px;
		}
	}
	.close-button {
		color: $coffee;
		margin-right: 1rem;
		padding-top: 0;
	}
	&.pdfViewer {
		@include flexbox;
		@include flex-direction(column);
		height: 90vh;
	}
	&.land-img {
		img {
			width: 100%;
			height: auto;
		}
	}
	&.medium {
		width: 55vw;
		max-width: 60rem;
	}
	&.med-lg {
		width: 70vw;
		max-width: 64rem;
	}
	&.paper {
		img { padding: 0 2rem; }
	}
}











